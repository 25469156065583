header {
    width: 100%;
    background-color: #FFFF;
    color: #000000;
}
.navbar-header{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.navbar {
    background-color: #FFFF !important;
    align-items: center;
    text-align: center;
    width: 100%;
}
/* .navbar-brand{
    font-size: 32px;
    font-weight: 700;
} */
.collapse{
    justify-content: space-between;
    margin-left: 30px;
    /* width: 100%; */
    background: transparent;
}

.nav-item {
    font-family: DM Serif Display;
    font-size: 24px;
    font-weight: 600;
    line-height: 21.94px;
    text-align: left;
    width: 100%;
    color: #000;
}
.nav-item a:hover{
    background-color: #e5301c;
    color: #ffffff !important;
    border-radius: 5px;
}
.nav-link button{
    width: 128px;
    border: none;
    border-radius: 8px;
    background-color: #E5301C;
    color: #fff ;
    font-size: 16px;
}
.nav-link button:hover{
    background-color: #fff !important;
    color: #E5301C !important;
    border: 2px solid #E5301C;
}
.navbar-brand img{
    width: 70px;
}
/* .contact{
    background: transparent;
} */