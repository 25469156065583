.plus-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.card-header {
  cursor: pointer;
  background-color: #fff;
}
.card-header:hover{
  background-color: #E5301C;
  color: #fff;
}
.curri {
  background-color: #fff;
  border: 0.5px solid #000;
  border-radius: 20px;
  margin-top: 90px;
}
h2{
  font-family: DM Serif Display;
  font-size: 32px;
  font-weight: 400;
  line-height: 43.87px;
  text-align: left;
}
h5 span{
  color: #fff;
  font-family: Lato;
  font-size: 32px;
  font-weight: 800;
  line-height: 38.4px;
  text-align: left;
}
.collapse {
  background-color: #f5f5f5;
  color: #E5301C;
  font-family: Lato;
  font-size: 18px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: left;

  margin: unset !important;
  padding: 1.2rem;
}
.card{

  padding: unset;
}
