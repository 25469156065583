body{
    padding: 0;
    background-color: #fff;
    font-family: Plus Jakarta Sans;
    /* user-select: none; */
}
.home{
    width: 100%;
    /* background-color: #c96; */
}
.row {
    width: 100%;
    margin: 0;
    padding: 0;
}

/* .hero {
    width: 100%;
    height: auto;
    position: relative;
    background: url(./Assets/unionImage.svg) no-repeat center center; 
    background-color: #fff !important;
    border-radius: 16px;
    background-size: cover;
    background-position: center;
} */
.hero {
    width: 100%;
    height: auto;
    position: relative;
    /* background-color: #fff !important; */
    border-radius: 16px;
    background-size: cover;
    background-position: center;
    align-items: center;
}
.hero_img {
    display: none;
    align-items: center;
    opacity: 20px;
 }
.hero_text{
    color: #fff;
}

.hero_text h1{
    font-size: 60px;
    font-weight: bold;
    line-height: 93.23px;
}
.hero_text p{
    font-size: 24px;
    font-weight: 500;
}
.hero button{
    /* width: 126px; */
    height: 46px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    border: none;
    background-color: #FFFFFF;
    color: #E5301C;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    margin-top: 20px;
}

.hero button:hover {
    background-color: #ff4500; 
    color: #fff;
  }
.why_corner {
    flex-wrap: wrap;
    justify-content: space-between;
  }
.why h1 {
    font-family: DM Serif Display;
    font-size: 48px;
    font-weight: 600;
    line-height: 65.81px;
    text-align: left;
}
.why p{
    font-family: DM Serif Display;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.why_corner {
    width: 100%;
    justify-content: space-between;
}
.why_box {
    width: 100%;
    max-width: 400px;
    justify-content: space-between;
    margin: 10px;
    background-color: #FFFF;
    border-radius: 16px;
    border: 2px solid #E5301C;
}
.why_box h1 {
    font-family: DM Serif Display;
    font-size: 24px;
    font-weight: 400;
    line-height: 32.9px;
}
.why_box p{
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #4F7396;
}
.program h1 {
    font-family: DM Serif Display;
    font-size: 40px;
    font-weight: 600;
    line-height: 54.84px;
}
.program p{
    font-family: DM Serif Display;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
}
.ready h1 {
    margin-top: 20px;
    font-family: DM Serif Display;
    font-size: 40px;
    font-weight: 600;
    line-height: 54.84px;
}
.ready p{
    font-family: DM Serif Display;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
}
.ready button {
    /* width: 100%; */
    height: 46px;
    padding: 12px 16px 12px 16px;
    gap: 4px;
    border-radius: 8px;
    border: none;
    background-color: #E5301C;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 10px;
}
.ready button:hover {
    background-color: #fff;
    color: #ff4500;
    border: 2px solid #E5301C;
  }
.curriculum h1{
    font-family: DM Serif Display;
    font-size: 40px;
    font-weight: 600;
    line-height: 54.84px;
    text-align: left;
}
.curriculum_box{
    width: 100%;
    justify-content: space-between;
}
.box {
    width: 270px;
    background-color: #fff;
    display: block;
    border-radius: 10px;
    border: 2px solid #E5301C;
    margin: 10px;
}
.box img {
    max-width: 265px;
    object-position: center;
    object-fit: cover;
    transition: 1.0s ease-in-out;
}
.box img:hover {
    transform: scale(1.1);
}
.box p{
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 5px;
    font-family: DM Serif Display;
    font-size: 24px;
    font-weight: 600;
    line-height: 32.9px;
    text-align: left;
}

.campaign-slider {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
  }
  .campaign-slide h1{
    font-size: 48px;
  }
  .campaign-slide {
    animation: fade-in-out 1s ease-in-out;
  }
  
  @keyframes fade-in-out {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  