footer{
    background-color: #000;
    width: 100%;
    color: #fff;
}
.btn {
    background-color: #8E0F01;
    color: #fff;
    width: 130px;
    height: auto;
    margin-left: 10px;
}
.btn:hover{
    background-color: #fff;
    color: #000;
    border: 2px solid #8E0F01;
}
.icon_text {
    display: flex;;
    gap: 10px;
}
.icon_text h4 {
    margin-right: 10px;
}

.icon_text a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}
.socials {
    display: flex;
}
.socials a {
    color: #000;
    transition: color 0.3s ease;;
    color: #fff !important;
    text-decoration: none !important;
}
.below{

    flex-wrap: wrap;
}
.socials a:hover {
    color: #8E0F01 !important;
}
.socials a a[href*="twitter.com"] {
    color: #fff;
}
.socials a[href*="twitter.com"]:hover {
    color: #ff4500;
}